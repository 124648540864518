import React from 'react';
import * as styles from './Button.module.scss';

interface Props extends React.HTMLAttributes<HTMLOrSVGElement> {
    children: React.ReactNode;
}

const Button = ({ children, ...rest }: Props): JSX.Element => (
    <button className={styles.button} type="button" {...rest}>
        {children}
    </button>
);

export default Button;
