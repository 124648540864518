import * as React from 'react';
import { navigate } from 'gatsby';
import Layout from '../layouts/Layout';

import Button from '../components/Button/Button';

import Space from '../components/Space/Space';

import * as containStyles from '../styles/contain.module.scss';

import MillerDisplay from '../components/Typography/MillerDisplay/MillerDisplay';
import Helvetica from '../components/Typography/Helvetica/Helvetica';
import Logo from '../assets/brewin-dolphin-logo.svg';

// markup
const NotFoundPage = (): JSX.Element => (
    <Layout>
        <title>Not found</title>
        <section className={`${containStyles.contain} ${containStyles.containMedium}`}>
            <div className="grid md:grid-cols-2 gap-8">
                <div className="md:space-y-16">
                    <img src={Logo} alt="Brewin Dolphin | Financial advice and investing" className="hidden md:inline-block" width={378} height={84} />
                    <Space spacing={4}>
                        <MillerDisplay tag="h1" typeset="title">404 - page not found</MillerDisplay>
                        <Helvetica tag="p" typeset="paragraph">Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Nam condimentum tempus diam, ultricies sollicitudin erat facilisis eget. Vestibulum rhoncus dui vel eros laoreet consectetur. Vivamus eget elementum ligula, vitae pharetra quam. Nullam at ligula sed metu</Helvetica>
                        <Button onClick={() => {
                            navigate('/');
                        }}
                        >
                            <span>Back to home</span>
                        </Button>
                    </Space>
                </div>
                <div className="flex items-center">
                    <img src="/images/dolphin.jpg" alt="A leaping dolphin" />
                </div>
            </div>
        </section>
    </Layout>
);

export default NotFoundPage;
